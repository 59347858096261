.fa-caret-up,
.fa-caret-down {
  margin-right: 0.25em;
  margin-top: -0.15em;
}

.fa-caret-up {
  color: green;
}

.fa-caret-down {
  color: red;
}
