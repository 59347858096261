#calcExchange {
  .desc {
    display: table;
    width: 100%;
    line-height: 2.8em;

    @media (max-width: 576px) {
      padding-right: 0.5rem;
      line-height: 2.6em;
    }
  }

  .currencyUp-list {
    padding: 1.25rem;

    @media (max-width: 576px) {
      padding: 0 0.5rem;
    }

    .col-sm-custom {
      padding: 0.5em 1em;
      color: #e8fcfb;
      font-size: 0.8em;
      text-transform: uppercase;
    }

    .row-custom {
      margin-bottom: 0.5em;
      &:last-child {
        margin-bottom: 0;
      }

      &:nth-last-child(2) {
        .row-custom-exchange-icon {
          display: none;
        }
      }
    }

    .desc--value-vertical-adjust {
      vertical-align: super;
    }

    .desc--value-line-adjust {
      line-height: 2em;
    }

    .row-custom-tableheader .col-sm-custom {
      background: rgba(232, 251, 251, 0.75);
      color: rgba(1, 36, 35, 0.65);
      padding: 0.5em 1em;
    }

    .col-sm-custom--as-headline {
      background: #012423;
    }

    .col-sm-custom-invest {
      background: rgba(232, 251, 251, 0.75);
      height: 3em;
      line-height: 2em;
      color: rgba(1, 36, 35, 0.65);
    }

    .row-custom-exchange-icon {
      padding-top: 0.5em;
      width: 100%;

      @media (max-width: 576px) {
        padding-top: 0;
      }

      svg {
        font-size: 1.5em;
        margin: auto;
      }
    }

    .desc--headline {
      display: block;
      float: left;
    }

    .desc--value {
      display: block;
      float: right;

      .fa-caret-up,
      .fa-caret-down {
        margin-top: 0;
      }
    }
  }

  .ReactTags__tagInput {
    float: left;
  }

  .currencyUp-tags {
    padding: 1.25rem 1.25rem 0;
    display: inline-block;

    @media (max-width: 576px) {
      padding: 0.5rem 0.5rem 0;
    }
  }

  .ReactTags__tags {
    display: block;
    float: left;
  }

  .tag-list {
    margin: 0;
    padding: 0;

    li {
      background: rgba(1, 36, 35, 0.75);
      border: 1px solid #e8fcfb;
      cursor: grab;
      float: left;
      padding: 0.25em 0.5em;
      margin-right: 0.5em;
      font-size: 1rem;
      display: block;
      min-height: 1.75rem;
      line-height: normal;
      color: #e8fcfb;
      padding-left: 2em;
      position: relative;

      &:active {
        cursor: grabbing;
      }

      @media (max-width: 576px) {
        margin-bottom: 0.5em;
        font-size: 0.9rem;
      }

      .fa-exchange {
        margin-right: 0.25em;
        left: 0.65em;
        position: absolute;
        width: 3.5em;
        top: 0.3em;
        cursor: grab;

        &:active {
          cursor: grabbing;
        }
      }

      .fa-times-circle {
        margin-left: 0.5em;
      }
    }
  }

  .dropdown-toggle--icon {
    font-size: 1.45em;
    float: left;
  }

  .btn-group {
    .dropdown-toggle {
      width: 200px;
      text-align: right;
      text-transform: uppercase;

      @media (max-width: 576px) {
        width: 175px;
        font-size: 14px;
      }

      @media (max-width: 340px) {
        width: 150px;
        font-size: 12px;
      }
    }
  }

  .row-custom-tableheader {
    text-transform: uppercase;
  }

  .ReactTags__tagInputField {
    display: inherit;
    font-size: 1rem;
    max-height: 1.75rem;
    padding: 0.25em 0.5em;
    border: 1px solid #e8fcfb;
    text-transform: uppercase;
  }

  .ReactTags__suggestions {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      width: 100%;
      max-width: 200px;

      li {
        background: rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid;
      }
    }
  }

  .currencyFixed {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .error-message {
    text-transform: uppercase;
    color: rgba(1, 36, 35, 0.75);
    font-size: 0.85rem;
    padding: 0.5rem 0 0;
    width: 100%;
  }
}

.sortable-tag-helper {
  background: rgba(1, 36, 35, 0.75);
  border: 1px solid #e8fcfb;
  cursor: grab;
  float: left;
  padding: 0.25em 0.5em;
  margin-right: 0.5em;
  font-size: 1rem;
  display: block;
  min-height: 1.75rem;
  line-height: normal;
  color: #e8fcfb;
  padding-left: 2em;
  position: relative;
  opacity: 0.5;

  &:before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f047";
    position: absolute;
    left: 0.75rem;
    top: 0.35rem;
  }

  .fa-exchange {
    display: none;
  }

  .fa-times-circle {
    display: none;
  }
}
