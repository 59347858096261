#calcCurrencies {
  .currencyUp-list {
    padding: 1.25rem;

    .col-sm-custom {
      padding: 0.5em 1em;
      color: #e8fcfb;
    }

    .row-custom-tableheader .col-sm-custom {
      background: rgba(232, 251, 251, 0.75);
      color: rgba(1, 36, 35, 0.65);
      padding: 0.5em 1em;
    }
  }

  .ReactTags__tagInput {
    float: left;
  }

  .currencyUp-tags {
    padding: 1.25rem 1.25rem 0;
    display: inline-block;
  }

  .ReactTags__tags {
    display: block;
    float: left;
  }

  .ReactTags__tag {
    background: rgba(1, 36, 35, 0.75);
    border: 1px solid #e8fcfb;
    float: left;
    padding: 0.25em 0.5em;
    margin-right: 0.5em;
    font-size: 1rem;
    display: block;
    min-height: 1.75rem;
    line-height: normal;
    color: #e8fcfb;
  }

  .row-custom-tableheader {
    text-transform: uppercase;
  }

  .ReactTags__tagInputField {
    display: inherit;
    font-size: 1rem;
    max-height: 1.75rem;
    padding: 0.25em 0.5em;
    border: 1px solid #e8fcfb;
    text-transform: uppercase;
  }

  .ReactTags__suggestions {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      width: 100%;
      max-width: 200px;

      li {
        background: rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid;
      }
    }
  }

  .currencyFixed {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .error-message {
    text-transform: uppercase;
    color: rgba(1, 36, 35, 0.75);
    font-size: 0.85rem;
    padding: 0.5rem 0 0;
    width: 100%;
  }
}
