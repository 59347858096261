.tipbot {
  margin-top: -35px;
  position: absolute;
  right: 1em;
  top: 50%;

  @media (max-width: 576px) {
    transform: scale(0.5);
    transform-origin: 100% 36px;
    right: 0;
  }

  &:before {
    content: "Support the developer";
    display: block;
    font-size: 12px;
  }
}
