h2 {
  padding: 0 1em;
  margin-bottom: 0.5em;
}

h3 {
  background: rgba(249, 255, 255, 0.9);
  text-transform: uppercase;
  top: 45px;
  top: 144px;
  width: calc(100% + 1.5em);
  margin-left: -0.75em;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  position: -webkit-sticky;
  position: sticky;
  padding: 0.25em 0.75em;
  z-index: 4;

  @media (max-width: 576px) {
    font-size: 1.35em;
    border-top: 1px solid rgba(1, 36, 35, 0.65);
    border-bottom: 1px solid rgba(1, 36, 35, 0.65);
    margin-bottom: 1rem;
    top: 45px;
  }

  &.no-space {
    margin-top: 0;
  }
}
