#cryptoNews {
  .row-custom {
    margin-bottom: 1.5em;

    @media (max-width: 576px) {
      margin-bottom: 1rem;
    }

    p {
      border-left: 5px solid rgba(1, 36, 35, 0.65);
      color: rgba(1, 36, 35, 0.65);
      padding: 1em;
      margin: 0 2rem;

      @media (max-width: 576px) {
        font-size: 0.8rem;
        margin: 0 1rem;
        padding: 1rem 0;
        border-left: none;
      }
    }
  }

  .row {
    position: relative;
    z-index: 3;

    h3 {
      background: rgba(1, 36, 35, 0.65);
      border: 3px solid transparent;
      color: #e8fcfb;
      font-size: 1.5rem;
      margin: 0;
      padding: 0;
      position: relative;
      text-transform: none;
      transition: all 0.2s;
      top: 0;
      width: 100%;

      span {
        display: block;
        max-width: 75%;
        padding: 0.5rem 1rem;

        @media (max-width: 576px) {
          font-size: 0.8em;
          max-width: 100%;
        }
      }
    }
  }

  .col-sm-custom {
    background: rgba(232, 251, 251, 0.75);
  }

  .news-source {
    color: #e8fcfb;
    padding: 0.5em;
    background: rgba(1, 36, 35, 0.65);
    padding: 0.5em 1rem;
    font-size: 0.7em;
    text-transform: uppercase;
    display: inline-block;
    float: right;
    border: 3px solid transparent;
    transition: all 0.2s;

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  a {
    text-decoration: none;
    overflow: hidden;
    transition: all 0.5s;

    h3,
    .news-source {
      transition: all 0.5s;
    }

    &:hover {
      transform: scale(1.02);

      @media (max-width: 576px) {
        transform: scale(1);
      }

      h3,
      .news-source {
        color: rgba(23, 34, 34, 0.65);
        background: #fff;
        border: 3px solid rgba(1, 36, 35, 0.65);
      }
    }
  }

  @media (max-width: 576px) {
    transform: scale(1);
  }
}
