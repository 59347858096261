footer {
  background: #012423;
  color: #e8fcfb;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1em;
  z-index: 7;

  @media (max-width: 576px) {
    font-size: 0.7rem;
  }

  button {
    background: none;
    border: none;
    color: #fff;
    text-transform: uppercase;
    margin-right: 0.5em;
  }

  li {
    list-style: none;
    float: left;
    margin-right: 1em;
    text-transform: uppercase;
  }
}

.no-guarantee {
  position: fixed;
  bottom: 92px;
  font-size: 10px;
  color: #999;
  background: #fff;
  width: 100%;
  left: 0;
  text-align: right;
  padding: 0.25em 1em;
  box-shadow: 0 -5px 30px rgba(0, 0, 0, 0.2);
  z-index: 4;
}
