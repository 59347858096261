.highcharts-container {
  max-width: 100%;
  width: 100% !important;

  svg {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    max-height: 500px;
  }

  .highcharts-background {
    @media (max-width: 576px) {
      max-height: 500px;
    }
  }

  // Price Trend Top
  .highcharts-series-4 {
    stroke-dasharray: 1, 3;
  }

  // Price Trend Bottom
  .highcharts-series-5 {
    stroke-dasharray: 1, 3;
  }
}

.pair-chart {
  clear: both;
}
