.help {
  h2 {
    @media (max-width: 576px) {
      font-size: 1.25rem;
    }
  }
  h3 {
    width: 100%;
    position: relative;
    margin: 0;
    top: inherit;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    border: 1px solid #212529;
    margin-bottom: 1rem;
    box-shadow: 2px 2px 5px rgba(10, 10, 10, 0.25);

    @media (max-width: 576px) {
      padding: 0.5rem 0.5rem;
      font-size: 1.2rem;
    }
  }

  p {
    padding: 0 1rem;

    @media (max-width: 576px) {
      padding: 0 0.5rem;
      font-size: 0.85rem;
    }
  }
}

.styles_modal__gNwvD {
  -webkit-overflow-scrolling: touch;
}
