.body {
  background: #f9ffff;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
}

header {
  background: #012423;
  color: #e8fcfb;
  padding: 1em 2em;
  margin-bottom: 1em;
  text-transform: uppercase;
  border-bottom: 1px solid #e8fcfb;
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px;
  z-index: 6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#millionCalculation {
  padding: 1em;
  margin-top: 170px;
}

.last-of-page {
  margin-bottom: 160px;
}

.row-custom-header .row-custom {
  margin-bottom: 0;
}

.no-space-container {
  margin: 0;
  padding: 0;
}

h1 {
  img {
    width: 6em;
  }

  span {
    font-size: 0.25em;
    position: absolute;
    top: -200px;
    letter-spacing: -300px;
  }
}

.desc {
  color: rgba(1, 36, 35, 0.65);
  font-size: 0.85rem;
  padding: 0.5em 1em;
  text-transform: uppercase;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
  background: rgba(232, 251, 251, 0.75);
}

.form {
  padding: 0 1em;
}

.row-custom {
  margin-bottom: 1em;
  margin-left: 0;
}

.col-sm-custom {
  background: rgba(1, 36, 35, 0.65);
  box-shadow: 10px 10px 10px rgba(10, 10, 10, 0.25);
  margin-right: 1em;
  border-radius: 3px;
  padding: 0;
}

.chartTimeButton {
  text-transform: uppercase;
  font-size: 0.7rem;
  margin-bottom: 0.25rem;
}

.row-custom-header {
  background: #fff;
  position: fixed;
  top: 90px;
  width: 100%;
  z-index: 5;
  margin-bottom: 0;
  padding: 0.5em;
  margin-left: 0 !important;
  margin-right: 0 !important;
  left: 0;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.25);

  .btn {
    border-radius: 5px 0 0 5px;
  }
}

#calculatorInput,
#calcExchange {
  .dropdown-item {
    &:nth-child(1),
    &:nth-child(2) {
      display: none;
    }
  }
}

.col-sm-custom-million {
  background: #ffe400;
  margin-right: 1em;
  position: fixed;
  left: 0;
  bottom: 58px;
  padding-right: 0;
  z-index: 7;

  .number {
    float: right;
    position: relative;
    bottom: 0;
    padding: 0 0.5em;
    text-transform: uppercase;
    font-size: 1em;
    background: #fff;
    margin: 4px 1em 0;
    border-radius: 5px;
    border: 2px solid rgba(1, 36, 35, 0.75);
  }

  .desc {
    background: transparent;
    color: #012423;
    float: left;
  }
}

.col-sm-custom {
  .number {
    color: #e8fcfb;
    font-size: 3vw;
    line-height: 1;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.15);

    &.smaler-font {
      font-size: 1.25em;
    }
  }
}

.number {
  font-size: 8vw;
  padding: 0.5em 1rem;
}

.inputLabel {
  line-height: 2.4em;
  margin-right: 0.5em;
  text-transform: uppercase;
}

.btn-secondary {
  color: #e8fcfb;
  background-color: rgba(1, 36, 35, 0.75);

  &:hover {
    background-color: rgba(1, 36, 35, 0.65);
  }
}

.glyphicon-refresh {
  margin-left: 0.5em;

  .fa-2x {
    cursor: pointer;
    color: rgba(1, 36, 35, 0.75);
    font-size: 1.75em;
    vertical-align: -webkit-baseline-middle;

    &:hover {
      color: rgba(1, 36, 35, 0.65);
    }
  }
}

.desc-aditional {
  cursor: pointer;
  float: right;
  line-height: normal;
}

.direction-icon {
  vertical-align: super;
}

.col-sm-custom-chart {
  height: auto;
  padding: 0.5em;
}

@media only screen and (min-width: 1124px) {
  .number {
    font-size: 2em;
  }

  .col-sm-custom .number {
    font-size: 1.5em;
  }
}

@media (max-width: 992px) {
  .container.container-custom {
    max-width: 100%;
  }

  .col-sm-custom .number {
    font-size: 2.25vw;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  .last-of-page {
    margin-bottom: 100px;
  }

  .no-guarantee {
    bottom: 68px;
  }

  .col-sm-custom .number {
    font-size: 4.5vw;
  }

  .glyphicon-refresh .fa-2x {
    font-size: 1.25em;
  }

  .inputLabel {
    font-size: 0.6rem;
    width: 100%;
  }

  header {
    padding: 0.5em;
    height: 40px;
  }

  h1 {
    font-size: 1rem;
  }

  .inputToggleClose {
    margin-top: -135px;
    transition: all 0.25s;
  }

  .inputToggleOpen {
    margin-top: 0;
    transition: all 0.25s;
    padding-bottom: 0.75em;
  }

  .row-custom-header {
    top: 40px;
    padding: 0;
    padding-bottom: 0;

    .input-group {
      padding-right: 15px;
    }
  }

  .col-sm-custom {
    box-shadow: 5px 5px 5px rgba(10, 10, 10, 0.25);
    margin-bottom: 0.5em;
  }

  .row-custom {
    margin-bottom: 0;
  }

  .col-sm-custom-chart {
    height: auto;
    padding: 0.25em;
  }

  .col-sm-custom-million {
    height: 30px;
    bottom: 40px;
  }

  .col-sm-custom-million .desc {
    font-size: 11px;
  }

  .col-sm-custom-million .number {
    font-size: 11px;
  }

  #millionCalculation {
    margin-top: 175px;
  }
}
