.trend {
  color: rgba(1, 36, 35, 0.65);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
  float: right;
  background: rgba(232, 251, 251, 0.75);
  padding: 0.1rem 1rem 0.3rem;
  font-size: 0.7rem;
  border-radius: 3px;

  @media (max-width: 576px) {
    float: left;
    margin: 0.25rem 0 0.5rem;
    padding: 0.1rem 0.5rem 0.3rem;
  }

  .trend--headline {
    text-transform: uppercase;
    float: left;
    margin-right: 1rem;
  }

  .trend--up,
  .trend--down {
    float: left;
    margin-right: 0.5rem;
  }

  .trend--up {
    margin-right: 0;
  }

  .trend-bar {
    position: relative;
    height: 4px;
    clear: both;
    top: 1px;
    border-radius: 4px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0000+0,ffd8d8+35,222222+40,666666+50,222222+60,222222+60,a7ff9e+65,008000+100 */
    background: rgb(255, 0, 0); /* Old browsers */
    background: -moz-linear-gradient(
      left,
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 216, 216, 1) 35%,
      rgba(34, 34, 34, 1) 40%,
      rgba(102, 102, 102, 1) 50%,
      rgba(34, 34, 34, 1) 60%,
      rgba(34, 34, 34, 1) 60%,
      rgba(167, 255, 158, 1) 65%,
      rgba(0, 128, 0, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 216, 216, 1) 35%,
      rgba(34, 34, 34, 1) 40%,
      rgba(102, 102, 102, 1) 50%,
      rgba(34, 34, 34, 1) 60%,
      rgba(34, 34, 34, 1) 60%,
      rgba(167, 255, 158, 1) 65%,
      rgba(0, 128, 0, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 216, 216, 1) 35%,
      rgba(34, 34, 34, 1) 40%,
      rgba(102, 102, 102, 1) 50%,
      rgba(34, 34, 34, 1) 60%,
      rgba(34, 34, 34, 1) 60%,
      rgba(167, 255, 158, 1) 65%,
      rgba(0, 128, 0, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#008000',GradientType=1 ); /* IE6-9 */

    .trend-bar-pointer {
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid rgba(1, 36, 35, 0.45);
      position: absolute;
      left: 50%;
      width: 8px;
      height: 8px;
      margin-left: -4px;
      margin-top: -2px;
      border-radius: 50%;
      transition: left 1s;
    }
  }
}
