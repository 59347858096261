.application-tooltip {
  text-transform: none;
  line-height: 1.5em;
  max-width: 250px;

  h3 {
    background: #c9dcdc !important;
    color: rgba(1, 36, 35, 0.65);
    font-size: 1rem;
    margin-top: 0;
    position: relative !important;
    display: block;
    top: inherit;
    text-transform: uppercase;
  }
}
